<template>
    <div>
        <b-modal id="modal-upload" hide-footer ref="modal-upload">
          <template #modal-title>
              Unggah Dokumen
          </template>
            <form class="form" @submit.stop.prevent="formOnsubmit()">
              
                <!-- File Image -->
                <b-form-group
                  id="input-group-file"                  
                >
                  <b-form-file
                    
                    placeholder="Unggah Dokumen Disini"
                    drop-placeholder="Drop file here..."
                    @change="upload"
                    ref="filesUpload"
                  ></b-form-file>

                  <small class="text-danger d-inline-block">{{ error.file }}</small>
                  
                  <small class="text-warning d-inline-block mt-2" v-if="storageFull == true">
                    <i class="fas fa-exclamation-triangle ml-1 text-warning"></i>
                    Penggunaan Penyimpanan Anda Melebihi Batas Maksimal, Silahkan Hapus Beberapa File
                  </small>
                  
                  <small class="mt-2 text-info d-inline-block">
                    <i class="fas fa-info-circle ml-1 text-info"></i>
                    Dokumen(PDF,DOCX,XLSX,PPT),Gambar(JPG,JPEG,PNG,SVG), Ukuran Max file 5 Mb
                  </small>
                </b-form-group>
                
              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div>
                  <b-button type="submit" variant="primary" v-if="storageFull == false && chooseFile == true">Simpan</b-button>
                  <b-button variant="primary" disabled v-if="storageFull == true">Simpan</b-button>
                  <b-button variant="primary" disabled v-if="storageFull == 'start'">Simpan</b-button>
                  <b-button
                    type="button"
                    class="ml-2"
                    variant="default"
                    @click="cancleUpload"
                  >
                    Batal
                  </b-button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
        </b-modal>
    </div>
</template>
<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
    data(){
       return{
           form:{
               id:"",
               files:[],
           },
           error:{
               files:"",
           },
            // data
            formData: new FormData(),
            user_data:"",
            sizeData:[],
            user_id:"",
            // other
            storageFull : false,
            chooseFile: false
       } 
    },
    methods:{
        
        async upload(event) {
            this.storageFull = "start"
            this.chooseFile = true
            this.formData = new FormData();
            //("event", event.target.files);
            let storage = 0
            
            // get user
            this.user_id = this.user_data.id

            // for (let a = 0; a < event.target.files.length; a++) {
              //   let formData = new FormData();
              this.formData.append('files',event.target.files[0]);
              storage = parseInt(event.target.files[0].size) 
              // //(event.target.files[a].size);
            // }

            let percent = await module.get(`api/storages/percent?id=${this.user_id}`);
            if(percent.length != 0){
              this.sizeData = percent[0]
              //("percent.length != 0", percent);
  
              let sum = storage/1000000
              sum = Math.round(sum)
              sum = sum + this.sizeData.size

              if(parseInt(sum) > 50){
                this.storageFull = true
              }else{
                this.storageFull = false
              }

            }else{
              //("percent.length == 0");
              let sum = storage/1000000
              sum = Math.round(sum)

              if(parseInt(sum) > 50){
                this.storageFull = true
              }else{
                this.storageFull = false
              }
            }

            // //(this.form.files);  
        },

        async formOnsubmit() {
        this.form.id = this.user_data.id
          for (const [key, value] of Object.entries(this.form)) {
            this.formData.append(key, value);
          }
          // Make Request
          //(this.formData);
          let response = await module.submit(this.formData, 'api/storages');
          // Check Response
          if (response.state == "error") {
            // Validation Error
            this.error = validation.setValidationError(
              this.error,
              response.error.errors
            );
          } else {
            // Success
            Swal.fire(response.success.title, response.success.message, "success");
            // this.$emit("successPost");
            this.$bvModal.hide('modal-upload')
            this.$emit('successAddFile')
          }
        },

        cancleUpload(){
          this.storageFull = false
          this.formData = new FormData()
          this.$refs.filesUpload.reset()
          this.$bvModal.hide('modal-upload')
        }
    },
    mounted() {
        this.user_data = getUser();
    },
}
</script>