<template>
  <div v-if="storageView">
    <v-app>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <b-alert
              show
              variant="light"
              class="alert alert-custom alert-white alert-shadow fade show"
            >
              <div class="alert-icon">
                <span class="svg-icon svg-icon-lg">
                  <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                </span>
              </div>
              <div class="alert-text">Daftar <b>File Pribadi</b></div>
              <b-button
                squared
                variant="primary"
                v-b-modal.modal-upload
                v-if="btnAccess"
                >Unggah</b-button
              >
            </b-alert>
          </div>

          <div class="col-12">
            <div class="row">
              <!-- storage usage -->
              <div class="col-12 col-md-6">
                <h5 class="font-weight-bold">Penggunaan Penyimpanan</h5>
                <div class="card">
                  <div class="card-body">
                    <k-progress
                      status="success"
                      type="line"
                      :percent="sizePercent.percent"
                    >
                    </k-progress>
                    <span class="mt-2 text-secondary">
                      {{ sizePercent.percent }}
                      dari 50Mb telah digunakan
                    </span>
                  </div>
                </div>
              </div>

              <!-- filter -->
              <div class="col-12 col-md-6">
                <h5 class="font-weight-bold">Saring</h5>
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-md-5">
                        <span class="d-block">Urut Berdasarkan</span>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            <i class="fas fa-filter"></i>
                          </b-input-group-prepend>
                          <b-form-select
                            v-model="filter.sortBy"
                            :options="options"
                            aria-placeholder="Urutkan"
                            @change="filterGenerate"
                          >
                            <!-- <template #first>
                                                      <b-form-select-option :value="null" disabled selected>-- Urut Berdasarkan --</b-form-select-option>
                                                    </template> -->
                          </b-form-select>
                        </b-input-group>
                      </div>
                      <div class="col-12 col-md-7">
                        <span class="d-block">Cari</span>
                        <b-input-group class="mb-2">
                          <b-input-group-prepend is-text>
                            <i class="fas fa-search"></i>
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="filter.name"
                            @keyup="filterGenerate"
                          ></b-form-input>
                          <b-input-group-append>
                            <button class="btn btn-danger" @click="filterReset">
                              Reset
                            </button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 mt-0">
            <h5 class="font-weight-bold">Daftar Dokumen</h5>
          </div>

          <!-- Document List -->
          <div class="col-12">
            <div class="row">
              <div
                class="col-6 col-md-2"
                v-for="{ index, file, file_name, type, id } in items"
                :key="index"
              >
                <div
                  class="card card-custom card-stretch gutter-b"
                  @click="downloadFile(file)"
                >
                  <div class="card-body">
                    <div class="row justify-content-end">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn dark icon v-bind="attrs" v-on="on">
                            <i class="fas fa-ellipsis-v"></i>
                          </v-btn>
                        </template>

                        <v-card
                          class="mx-auto"
                          max-height="300px"
                          min-width="30%"
                          tile
                        >
                          <v-list dense>
                            <v-list-item-group color="primary" min-width="150">
                              <v-list-item>
                                <v-list-item-content @click="fileDelete(id)">
                                  Hapus
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </div>

                    <a
                      :href="file"
                      download="file"
                      style="text-decoration: none"
                      class="text-primary"
                      target="_blank"
                    >
                      <div class="row justify-content-center">
                        <div class="col-12 d-flex justify-content-center">
                          <i
                            class="fas fa-file fa-5x"
                            v-if="
                              type != 'jpg' ||
                              type != 'png' ||
                              type != 'jpeg' ||
                              type != 'svg'
                            "
                          >
                          </i>
                        </div>

                        <div class="col-12 d-flex justify-content-center">
                          <span class="text-teal">{{ file_name }}</span>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
          <!-- end Document List -->
        </div>
      </div>
      <Modal @successAddFile="pagination" />
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Modal from "@/view/components/personalStorage/uploadModal.vue";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import KProgress from "k-progress";

export default {
  name: "File Personal",
  components: {
    Modal,
    KProgress,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 18,
      currentPage: 1,
      totalRows: 1,
      items: [],
      sizeData: [],
      sizePercent: {
        percent: 0,
        size: 0,
      },
      user_id: "",
      user: [],
      // filter
      filter: {
        sortBy: "",
        name: "",
      },
      // options
      options: [
        { value: "type", text: "Tipe" },
        { value: "size", text: "Ukuran" },
        { value: "nameAsc", text: "Nama (A-Z)" },
        { value: "nameDesc", text: "Nama (Z-A)" },
      ],
      // access
      storageView: false,
      btnAccess: false,
    };
  },
  methods: {
    downloadFile(url) {
      // var downloadUrl = ;

      var downloading = download({
        url: url,
        filename: "my-file",
        conflictAction: "uniquify",
      });
    },
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },
    async pagination() {
      this.user = getUser();
      this.user_id = this.user.id;
      let filterParams = `&id=${this.user_id}&name=${this.filter.name}&sortBy=${this.filter.sortBy}`;
      let response = await module.paginate(
        "api/storages",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

      // get percent
      let percent = await module.get(`api/storages/percent?id=${this.user_id}`);
      if (percent.length != 0) {
        this.sizeData = percent[0];
        this.sizePercent = percent[0];
        //("size", this.sizeData);
        //("items", this.items);
      } else {
        // this.sizePercent = 0
      }
    },

    filterGenerate() {
      this.pagination();
    },

    filterReset() {
      this.filter.sortBy = "";
      this.filter.name = "";
      this.pagination();
    },

    async fileDelete(id) {
      // Delete Data
      let result = await module.delete(`api/storages/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3001") {
          this.storageView = true;
        }
        if (access_right[a] == "3002") {
          this.storageView = true;
          this.btnAccess = true;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "File Pribadi" }]);

    this.pagination();
    this.checkAccess();
  },
};
</script>

<style>
</style>